import { Atom, atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { parse as parseYaml } from "yaml";

interface Platform {
  url: string;
  installerUrl: string;
  signature?: string;
}

type PlatformName = "windows-x86_64";

interface UpdateTauriMeta {
  version: string;
  notes?: string;
  platforms: Record<PlatformName, Platform>;
}

interface UpdateLegacyMeta {
  version: string;
  files: Array<{
    url: string;
  }>;
}

export interface UpdateInfo {
  version: string;
  url: string;
}

function makeUpdateLegacyMetaAtom(url: string) {
  return atom(async () => {
    const response = await fetch(url);

    const data: UpdateLegacyMeta = parseYaml(await response.text());

    return data;
  });
}

function makeUpdateTauriMetaAtom(url: string) {
  return atom(async () => {
    const response = await fetch(url);

    const data: UpdateTauriMeta = await response.json();

    return data;
  });
}

function makeUpdateLegacyAtom(
  atom: Atom<Promise<UpdateLegacyMeta>>,
  urlPrefix: string
) {
  return selectAtom(
    atom,
    (v): UpdateInfo => ({
      url: `https://tocteam-apps.s3.eu-central-1.amazonaws.com/${urlPrefix}/${v.files[0].url}`,
      version: v.version
    })
  );
}

function makeUpdateTauriAtom(atom: Atom<Promise<UpdateTauriMeta>>) {
  return selectAtom(
    atom,
    (v): UpdateInfo => ({
      url: v.platforms["windows-x86_64"].installerUrl,
      version: v.version
    })
  );
}

const updateEnvOneMetaAtom: Atom<Promise<
  UpdateTauriMeta
>> = makeUpdateTauriMetaAtom(
  "https://tocteam-apps.s3.eu-central-1.amazonaws.com/mpi-env-1/update.json"
);

const updateDesktopMetaAtom = makeUpdateLegacyMetaAtom(
  "https://tocteam-apps.s3.eu-central-1.amazonaws.com/mpi-desktop/latest.yml"
);

const updateEnvMetaAtom = makeUpdateLegacyMetaAtom(
  "https://tocteam-apps.s3.eu-central-1.amazonaws.com/mpi-env/latest.yml"
);

export const envOneUpdateAtom = makeUpdateTauriAtom(updateEnvOneMetaAtom);

export const desktopUpdateAtom = makeUpdateLegacyAtom(
  updateDesktopMetaAtom,
  "mpi-desktop"
);

export const envUpdateAtom = makeUpdateLegacyAtom(updateEnvMetaAtom, "mpi-env");
